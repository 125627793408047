/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, { useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import Classes from "./index.css";
import { capitalizeFirstLetter, isMobileOrTablet } from "../Utils";
import parser from "html-react-parser";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu";
// import RigVedQuotes from "../RigvedQuotes";

const NavWrapper = styled.div`

// .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
//     color: rgb(8, 63, 136) !important;
// }
.navbar-dark .navbar-nav .nav-link{
    color: white;
    z-index: 1200;
    // font-weight:600;
    white-space:nowrap;
    text-decoration: none;
}
@media(min-width:992px){
  .navbar-dark .navbar-nav .nav-link{
    margin:0px 2rem;
  }
  .navbar-expand-lg .navbar-nav{
    margin-left:0rem;
  }
}
// .nav-item.show > a{
//     color: #083F88!important;
// }
.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin: 0px 0px 0 -30px !important;
}

._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
    font-weight: 600;
}
}
.dropdown-item.active, .dropdown-item:active {
    color: #083F88;
    text-decoration: none;
    background-color:transparent;
    border-left: 4px solid #083F88;
    border-right: 4px solid #083F88;
    transition: all 0.1s linear;
    border-radius:2px;
}
.dropdown-item{
    border-bottom:2px solid transparent;
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color:transparent;
}
 .dropdown-item:hover{
    // border-bottom:2px solid  #083F88 !important;
    text-decoration: none;
}

@media(max-width:767px){
    .dropdown-item{
        border-bottom:0px transparent !important;
    }
    .dropdown-item:hover{
        border-bottom: 0px transparent !important;

    }
}

#dropdown-item-button::after{
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA8ElEQVQ4jWNgGAUowHLOVSHjmc+4cMkbz3zGZTnnqhCyGBOMYTD/vsDvP2w7mf5/26E99SoPNs2M/79s+vWHbZ/xzJsiGAaw/PpbycDAYPKf4b8tFzPbRmSXGM98xsX0/+tmBgZGZ0YGBn1GBuZSuD4Y4z/DhxoGBj5VRgbGwP8MDE5Ql3hxsAj+Y/z/ZdN/BkYniErGrR9+/q+D6WNEdeYZVob/fCsZGRgDoZL7IIYzOEHo/+sZGD+Fn003+Y3VAGyGIADj1g+//gffyVP9iSKKbgB2Q7BrxmkAqiFMbLg0EwTGM8+wGs88w0qyxqEFAFtSY2S3+RTSAAAAAElFTkSuQmCC");
    // vertical-align: middle;
    vertical-align: -0.09em ;
    border:none;
    margin-top:5px;
}
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-color:white;
}
.btn-primary:focus {

    border-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}

.channelsNav{
    .dropdown-submenu-container a.dropdown-submenu::after {
        position: absolute;
        right: 0;
        top: 0;

    }
.dropdown-menu{
    .dropdown-toggle::after {
        content: ">";
        vertical-align: middle;
        border: none;
        margin-top: 4px;
        font-weight: 600;
        color: #dddd;
        transform: rotate(0) !important;
    }
    padding-right:15px !important;
}
}
// @media(min-width:1067px) and (max-width:1201px){
//     .navbar-dark .navbar-nav .nav-link{
//         font-size: 14px !important;
//         font-weight:600;
//     }
//     }

    @media(min-width:992px) and (max-width:1067px){
        // .navbar-dark .navbar-nav .nav-link{
        //     font-size: 13px !important;
        //     font-weight: 500;
        //     padding: 10px 5px;
        // }
        .eEKJvu ._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
            font-weight: 600;
            font-size: 14px;
        }
        }
@media(max-width:992px){
.navbar-dark .navbar-toggler{
background-color:#00AAE4;
// border-color:blue;
}

`;

const HeaderNavbar = ({
  aiBrandingData,
  loanOfficerActive,
  loanOfficerData
}) => {
  const [navbar, setNavbar] = useState(false);
  const [showRigVedQuotes, setShowRigVedQuotes] = useState(false);
  const isMobileOrTabletDevice = isMobileOrTablet();
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";
  // const seperator = imgCDNURL + "seperater.webp";

  let prevScrollPos = window.scrollY;

  window.onscroll = function() {
    const currentScrollPos = window.scrollY;
    const menuNavbar = document.getElementById("menuNavbar");

    if (currentScrollPos === 0) {
      menuNavbar.classList.remove("navbarTransition");
      setNavbar(false);
    } else {
      if (prevScrollPos > currentScrollPos) {
        // Perform your desired actions for scrolling up here
        setNavbar(true);
      } else if (prevScrollPos < currentScrollPos) {
        // Perform your desired actions for scrolling down here
        if (!isMobileOrTabletDevice) {
          setNavbar(true);
        } else {
          setNavbar(false);
          menuNavbar.classList.add("navbarTransition");
        }
      }
    }

    prevScrollPos = currentScrollPos;
  };

  function scrollToSection1() {
    const sectionPosition = document.getElementById("contact-wrapper").offsetTop;
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div>
        <NavWrapper>
          <Navbar
            id="menuNavbar"
            collapseOnSelect
            expand="lg"
            fixed="top"
            bg="dark"
            variant="dark"
            className={navbar ? "navbar active" : "navbar"}
          >
            <Navbar.Brand className="ps-3">
              <div className="d-flex">
                <div>
                  <a
                    href="https://www.swmc.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Sunwest Mortgage Company, Inc."
                    aria-label="Sunwest mortgage Company, Inc."
                  >
                    <img
                      src={process.env.REACT_APP_CDN_LINK + "swmc-images/sunwest-trust-white-logo.png"}
                      className="main_logo img-fluid"
                      alt="SWMC Logo"
                      title="SWMC Logo"
                      aria-label="SWMC Logo"
                    />
                  </a>
                </div>
                <UserWayAccessibilityMenu color="white" width={40} height={40} />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto" />
              <Nav className={Classes.nav_temp}>
                {/* <Button
                  variant="link"
                  className="navbar-dark navbar-nav nav-link getlink"
                  onClick={scrollToSection}
                  title="Get TRU Approved"
                  aria-label="Get TRU Approved"
                >
                  Get TRU Approved
                </Button> */}
                {loanOfficerActive ? (
                  <>
                    {/* <img
                      src={seperator}
                      className="seperator img-fluild"
                      alt="seperator"
                    ></img> */}
                    <a
                      className="navbar-dark navbar-nav nav-link"
                      href={"https://"+loanOfficerData.staff.loanOfficer.loWebsite + "/#loan_service"}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={
                        loanOfficerData
                          ? "Loan Services offered by " +
                            capitalizeFirstLetter(loanOfficerData.staff.loanOfficer.loName)
                          : "Loan Services offered"
                      }
                      aria-label="Loan Services offered"
                    >
                      Loan Services
                    </a>
                  </>
                ) : (
                  "Error"
                )}

                <div className="contactbutt">
                  <Button
                    variant="link"
                    className="navbar-dark navbar-nav nav-link"
                    onClick={scrollToSection1}
                    title="Contact Us"
                    aria-label="Contact Us"
                  >
                    Contact Me
                  </Button>
                </div>
              </Nav>
            </Navbar.Collapse>
            {/* <div className="brandTagLineContainerDesktop">
              <Button
                variant="primary"
                className="brandTagLine"
                onClick={() => {
                  setShowRigVedQuotes(true);
                }}
                onMouseOver={() => {
                  setShowRigVedQuotes(true);
                }}
                onMouseOut={() => {
                  setShowRigVedQuotes(false);
                }}
                aria-label="Brand Tag Line"
              >
                {aiBrandingData ? parser(aiBrandingData.current.brandTagLine ? aiBrandingData.current.brandTagLine : "") : ""}
              </Button>
              <RigVedQuotes showRigVedQuotes={showRigVedQuotes} />
            </div> */}
          </Navbar>
        </NavWrapper>
        {/* <div className="brandTagLineContainerMobile">
          <Button
            variant="primary"
            className="brandTagLine"
            onClick={() => {
              setShowRigVedQuotes(true);
            }}
            onMouseOver={() => {
              setShowRigVedQuotes(true);
            }}
            onMouseOut={() => {
              setShowRigVedQuotes(false);
            }}
            aria-label="Brand Tag Line"
          >
            {aiBrandingData ? parser(aiBrandingData.current.brandTagLine ? aiBrandingData.current.brandTagLine : "") : ""}
          </Button>
          <RigVedQuotes showRigVedQuotes={showRigVedQuotes} />
        </div> */}
      </div>
    </>
  );
};

export default HeaderNavbar;
