import React from "react";
import ScrollToTop from "react-scroll-to-top";

export default function ScrollUp() {
  return (
    <ScrollToTop
      width={20}
      height={15}
      smooth
      component={<div className="scrollToTop-arrows">{"<<"}</div>}
      top={100}
      color={"white"}
      className="scrollToTop-button"
    />
  );
}
