/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import { Container, Row, Col } from "react-bootstrap";
import "./Body.css";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  addInternationalCode,
} from "../Utils";
import parser from "html-react-parser";

export default function Body({ loanOfficerActive, loanOfficerData, angelAiUserInfo }) {

  const imgCDNURL = process.env.REACT_APP_CDN_LINK + "marketing-lo-website/";

  const loImage = loanOfficerData ? loanOfficerData.staff.loanOfficer.lobannerImage : "";
  const loanOfficerName = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.staff.loanOfficer.loName) : "";
  const loanOfficerPhoneNumber = loanOfficerData ? (loanOfficerData.staff.loanOfficer.loPhone ? loanOfficerData.staff.loanOfficer.loPhone : loanOfficerData.loPhone) : "";
  const loanOfficerPhoneNumberFormatted = loanOfficerData ? formatPhoneNumber(loanOfficerPhoneNumber) : "";
  const loanOfficerPhoneNumberWithInternationalCode = loanOfficerData ? addInternationalCode(loanOfficerPhoneNumber, "+1") : "";
  const loanOfficerPhoneNumberInTitle = loanOfficerData ? "Contact me at " + loanOfficerPhoneNumberFormatted : "";

  return (
    <>
      <div className="banner">
        <Container>
          <Row className="banner-row">
            <Col sm={12} md={12} xl={6} xxl={6} lg={6} className="welcome-col">
              <div className="joaquinsec">
                {loanOfficerActive && loanOfficerData ? (
                  <>
                    <h1 className="title">{parser(loanOfficerData.banner.welcome)}</h1>
                    <h1 className="lo-website">{parser(loanOfficerData.banner.lowebsite)}</h1>
                    <p className="banner-para">{parser(loanOfficerData.banner.description)}</p>
                  </>
                ) : (
                  "Error: No loan officer data found."
                )}
              </div>
            </Col>
            <Col sm={12} md={12} xl={6} xxl={6} lg={6} className="lo-banner-col">
              <div className="lo-section">
                {loanOfficerActive && loanOfficerData ? (
                  <>
                    <img src={imgCDNURL + loImage} alt={loanOfficerName} title={loanOfficerName} className="lo-banner-img" width="300px" height="280px"/>
                    <h2 className="lo-banner-name">{parser(loanOfficerName)}</h2>
                    <h3 className="lo-banner-nmls">{parser(loanOfficerData.staff.loanOfficer.loNMLSID)} | {parser(loanOfficerData.staff.loanOfficer.loDesignation)}</h3>
                    <p className="lo-banner-mob">D:{" "}
                      {loanOfficerPhoneNumber ? (
                        <>
                          <a
                            href={"tel:" + loanOfficerPhoneNumberWithInternationalCode}
                            title={loanOfficerPhoneNumberInTitle}
                            aria-label={loanOfficerPhoneNumberInTitle}
                          >
                            {loanOfficerPhoneNumberFormatted}
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    <br/>
                      <a
                        href={"mailto:" + loanOfficerData.staff.loanOfficer.loEmailID}
                        title={"Email me at " + loanOfficerData.staff.loanOfficer.loEmailID}
                        aria-label={"Email me at " + loanOfficerData.staff.loanOfficer.loEmailID}
                      >
                        {loanOfficerData.staff.loanOfficer.loEmailID}
                      </a>
                    <br/>
                      <a
                        href={"https://" + loanOfficerData.staff.loanOfficer.loWebsite}
                        title={loanOfficerData.staff.loanOfficer.loWebsite}
                        aria-label={loanOfficerData.staff.loanOfficer.loWebsite}
                        target="_blank"
                      >
                        {loanOfficerData.staff.loanOfficer.loWebsite.replace("https://", "")}
                      </a>
                    <br/>
                      <a
                        href={loanOfficerData.staff.loanOfficer.loAddressMaplink}
                        target="_blank"
                      >
                        {parser(loanOfficerData.staff.loanOfficer.loBranchAddress)}
                      </a>
                    <br/>
                        {parser(loanOfficerData.staff.loanOfficer.lobranchNMLS)}
                    </p>
                  </>
                ) : (
                  "Error: No loan officer data found."
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </>
  );
}
