import React from "react";
import { Routes, Route } from "react-router-dom";
import AgentHomePage from "./AgentHomePage";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/:agentName" element={<AgentHomePage />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
